<template>
  <div v-if="!childrenPage">
    <div class="text-center fixed inset-x-0 bottom-0 z-10">
      <div class="bg-white container mx-auto grid grid-cols-4 gap-5 p-1 sm:w-full md:w-4/12">
        <div>
          <router-link :to="{ name: 'home' }" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center">
            <div v-if="$route.name == 'home'">
              <div class="w-1/2 h-1 mx-auto bg-yellow-500 rounded">&nbsp;</div>
              <img
                class="inline-block"
                width="25"
                height="25"
                src="@/assets/images/HomeActive.png"
              />
              <span class="block icon-text font-bold text-yellow-500">Beranda</span>
            </div>
            <div v-else>
              <div class="w-1/2 h-1 mx-auto bg-white rounded">&nbsp;</div>
              <img
                class="inline-block"
                width="25"
                height="25"
                src="@/assets/images/HomeInactive.png"
              />
              <span class="block icon-text">Beranda</span>
            </div>
          </router-link>
        </div>

        <div>
          <router-link :to="{ name: 'campaign.index' }" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center">
            <div v-if="$route.name == 'campaign.index'">
              <div class="w-1/2 h-1 mx-auto bg-yellow-500 rounded">&nbsp;</div>
              <img
                width="25"
                height="25"
                class="inline-block"
                src="@/assets/images/ProgramActive.png"
              />
              <span class="tab tab-kategori block icon-text font-bold text-yellow-500">Program</span>
            </div>
            <div v-else>
              <div class="w-1/2 h-1 mx-auto bg-white rounded">&nbsp;</div>
              <img
                width="25"
                height="25"
                class="inline-block"
                src="@/assets/images/ProgramInactive.png"
              />
              <span class="tab tab-kategori block icon-text">Program</span>
            </div>
          </router-link>
        </div>

        <!-- <div>
          <router-link :to="{ name: 'campaign.index' }" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center">
            <img
              width="25"
              height="25"
              class="inline-block"
              src="@/assets/images/Donation1.png"
            />
            <span class="tab tab-kategori block icon-text">Sedekah</span>
          </router-link>
        </div> -->
        <div>
          <router-link :to="{ name: 'donation.index' }" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center">
            <div v-if="$route.name == 'donation.index'">
              <div class="w-1/2 h-1 mx-auto bg-yellow-500 rounded">&nbsp;</div>
              <img
                width="25"
                height="25"
                class="inline-block"
                src="@/assets/images/ListActive.png"
              />
              <span class="tab tab-kategori block icon-text font-bold text-yellow-500">Donasiku</span>
            </div>
            <div v-else>
              <div class="w-1/2 h-1 mx-auto bg-white rounded">&nbsp;</div>
              <img
                width="25"
                height="25"
                class="inline-block"
                src="@/assets/images/ListInactive.png"
              />
              <span class="tab tab-kategori block icon-text">Donasiku</span>
            </div>
          </router-link>
        </div>

        <div>
          <router-link :to="{ name: 'dashboard' }" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center">
            <div v-if="$route.name == 'dashboard'">
              <div class="w-1/2 h-1 mx-auto bg-yellow-500 rounded">&nbsp;</div>
              <img
                width="25"
                height="25"
                class="inline-block"
                src="@/assets/images/UserActive.png"
              />
              <span class="tab tab-kategori block icon-text font-bold text-yellow-500">Akun</span>
            </div>
            <div v-else>
              <div class="w-1/2 h-1 mx-auto bg-white rounded">&nbsp;</div>
              <img
                width="25"
                height="25"
                class="inline-block"
                src="@/assets/images/UserInactive.png"
              />
              <span class="tab tab-kategori block icon-text">Akun</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
        childrenPage() {
          return this.$route.name == 'campaign.show' || 
          this.$route.name == 'donation.create' || 
          this.$route.name == 'qurban.create' || 
          this.$route.name == 'category.show' ||
          this.$route.name == 'campaign.program.index' ||
          this.$route.name == 'privacy' ||
          this.$route.name == 'profile' ||
          this.$route.name == 'profile.password' ||
          this.$route.name == '404' ||
          this.$route.name == 'news.index'
        }
      },
  }
</script>

<style>
.icon-text {
  font-size: 8pt;
}
</style>
