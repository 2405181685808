//import vue router
import { createRouter, createWebHistory } from 'vue-router'

//import store vuex
import store from '@/store'

//define a routes
const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import( /* webpackChunkName: "register" */ '@/views/404/Index.vue')
    },
    {
        path: '/register',
        name: 'register',
        redirect: '/:notFound'
    },
    {
        path: '/login',
        name: 'login',
        redirect: '/:notFound'
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        redirect: '/:notFound'
    },
    {
        path: '/donation',
        name: 'donation.index',
        redirect: '/:notFound'
    },
    {
        path: '/profile',
        name: 'profile',
        redirect: '/:notFound'
    },
    {
        path: '/profile/password',
        name: 'profile.password',
        redirect: '/:notFound'
    },
    {
        path: '/',
        name: 'home',
        redirect: '/:notFound'
    },
    {
        path: '/category',
        name: 'category.index',
        redirect: '/:notFound'
    },
    {
        path: '/category/:slug',
        name: 'category.show',
        redirect: '/:notFound'
    },
    {
        path: '/campaign',
        name: 'campaign.index',
        redirect: '/:notFound'
    },
    {
        path: '/campaign-program/:id',
        name: 'campaign.program.index',
        redirect: '/:notFound'
    },
    {
        path: '/campaign/:slug',
        name: 'campaign.show',
        redirect: '/:notFound'
    },
    {
        path: '/campaign/:slug/kabar-terbaru',
        name: 'news.index',
        redirect: '/:notFound'
    },
    {
        path: '/donation/create/:slug',
        name: 'donation.create',
        redirect: '/:notFound'
    },
    {
        path: '/qurban/create/:slug',
        name: 'qurban.create',
        redirect: '/:notFound'
    },
    {
        path: '/search',
        name: 'search',
        redirect: '/:notFound'
    },
    {
        path: '/privacy',
        name: 'privacy',
        redirect: '/:notFound'
    },
]

//create router
const router = createRouter({
    history: createWebHistory(),
    routes // <-- routes,
})

//define route for handle authentication
router.beforeEach(async (to) => {
    if (to.meta.requiresAuth && !store.getters['auth/isLoggedIn']) {
        if (to.name.includes('login')) {
            return true;
        } else {
            return {
                name: 'login',
                force: true
            }
        }
    }
})

router.onError(error => {

    if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
        window.location.reload()
    }

})

export default router