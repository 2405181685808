<template>
  <div class="h-screen">
    
    <!-- Header -->
    <Header />

    <!-- content -->
    <router-view></router-view>

    <!-- footer -->
    <Footer />

  </div>
</template>

<script>

  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'

  export default {
    data: function() {
      return {
        currentRoute: window.location.pathname
      }
    },
    watch:{
      $route(){

        console.log('to '+this.$route.name);
      }
    },
    components: {
      Header,
      Footer
    }
  }
</script>

<style>

</style>
