<template>
    <div>
        <!-- header -->
        <header>
            <div v-if="!notFoundPage" class="bg-yellow-500 text-white text-center fixed inset-x-0 top-0 z-10">
                <div class="container mx-auto grid grid-cols-10 p-2 sm:w-full md:w-4/12">
                    <div v-if="childrenPage" class="col-span-1 h-10 w-10 p-1 mr-3">
                        <a @click="backButton">
                            <img src="@/assets/images/Back.png" class="inline-block">
                        </a>
                    </div>
                    <div v-if="childrenPage" class="col-span-9 inline-block">
                        <div v-if="this.$route.name == 'news.index'">
                            <p class="pl-1 pt-1.5 text-base text-left font-semibold truncate align-middle">
                                Kabar terbaru
                            </p>
                        </div>
                        <div v-else-if="this.$route.name == 'category.index'">
                            <p class="pl-1 pt-1.5 text-base text-left font-semibold truncate align-middle">
                                Kategori Lainnya
                            </p>
                        </div>
                        <div v-else-if="this.$route.name == 'category.show'">
                            <p class="pl-1 pt-1.5 text-base text-left font-semibold truncate align-middle">
                                Kategori {{ category.name }} -                         
                                <router-link :to="{name: 'home'}">
                                    <button style="background: green; border-radius: 5px; padding-left: 5px; padding-right: 5px"><strong>Halaman Utama</strong></button>
                                </router-link>

                            </p>
                        </div>
                        <div v-else-if="this.$route.name == 'profile'">
                            <p class="pl-1 pt-1.5 text-base text-left font-semibold truncate align-middle">
                                Ubah Profil
                            </p>
                        </div>
                        <div v-else-if="this.$route.name == 'profile.password'">
                            <p class="pl-1 pt-1.5 text-base text-left font-semibold truncate align-middle">
                                Ubah Password
                            </p>
                        </div>
                        <div v-else-if="this.$route.name == 'privacy'">
                            <p class="pl-1 pt-1.5 text-base text-left font-semibold truncate align-middle">
                                Kebijakan Privasi
                            </p>
                        </div>
                        <div v-else>
                            <p class="pl-1 pt-1.5 text-base text-left font-semibold truncate align-middle">
                                {{ campaign.title }}
                            </p>
                        </div>
                    </div>
                    <div v-if="!childrenPage"
                        class="col-span-2 lg:col-span-1 bg-white rounded-full h-10 w-10 p-1 shadow-sm">
                        <router-link :to="{name: 'home'}">
                            <img src="@/assets/dyd.png" class="inline-block">
                        </router-link>
                    </div>
                    <div v-if="!childrenPage" class="col-span-8 lg:col-span-8">
                        <input type="text" @click="linkToSearch" v-model="search" @keyup="searchQuery"
                            class="p-1.5 appearance-none w-full bg-yellow-600 rounded-full shadow-md placeholder-white focus:outline-none focus:placeholder-gray-600 focus:bg-white focus-within:text-gray-600"
                            placeholder="Cari yang ingin kamu bantu">
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script>

    //hook vue
    import { computed, ref } from 'vue'

    //hook vue router
    import { useRouter } from 'vue-router'

    //hook vuex
    import { useStore } from 'vuex'

    export default {
        computed: {
            childrenPage() {
                return this.$route.name == 'campaign.show' ||
                    this.$route.name == 'donation.create' ||
                    this.$route.name == 'category.index' ||
                    this.$route.name == 'category.show' ||
                    this.$route.name == 'campaign.program.index' ||
                    this.$route.name == 'news.index' ||
                    this.$route.name == 'privacy' ||
                    this.$route.name == 'profile' ||
                    this.$route.name == 'profile.password' ||
                    this.$route.name == 'qurban.create'
            },
            notFoundPage() {
                return this.$route.name == '404'
            }
        },
        setup() {

            //router
            const router = useRouter()

            //store
            const store = useStore()

            //state seacrh
            const search = ref(null)

            // back button pressed
            function backButton() {
                router.go(-1)
                store.state.campaign.campaign = {}
            }

            //queryString
            function searchQuery() {
                store.dispatch('campaign/searchCampaign', search.value)
            }

            //redirect to route search
            function linkToSearch() {
                router.push({
                    name: 'search'
                })
            }

            //digunakan untuk get data state "category" di module "category" 
            const category = computed(() => {
                return store.state.category.category
            })

            // ambil title campaign ketika user berada di detail campaign
            const campaign = computed(() => {
                return store.state.campaign.campaign
            })

            return {
                backButton,
                search,         // <-- state search
                linkToSearch,   // <-- method linkToSearch
                searchQuery,     // <-- method searchQuery
                category,
                campaign
            }
        }

    }
</script>

<style>
    .hide-search-bar {
        visibility: hidden;
    }
</style>