const mixins = {

    methods: {

        /**
         * 
         * @param {*} value 
         */
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        /**
         * 
         * @param {*} partialValue 
         * @param {*} totalValue 
         */
        percentage(partialValue, totalValue) {
            return (100 * partialValue) / totalValue;
        },

        /**
         * 
         * @param {*} maxDate 
         */
        maxDate(maxDate) {

            let date = new Date()
            let now = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)

            //if same date
            if (now == maxDate) {
                return true
            }

            //if now > max date
            if (now > maxDate) {
                return true
            }
            return false
        },

        /**
         * 
         * @param {*} maxDate 
         */
        countDay(maxDate) {

            let date = new Date()
            let now = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)

            let dt1 = new Date(now);
            let dt2 = new Date(maxDate);

            let result = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
                Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));

            if (result < 0) {
                return 0
            }

            return result

        },

        /**
         * 
         * @param {*} num 
         * @param {*} digits
         */
        nFormatter(num, digits) {
            const lookup = [
                { value: 1, symbol: "" },
                { value: 1e3, symbol: "rb" },
                { value: 1e6, symbol: "jt" },
                { value: 1e9, symbol: "M" },
                // { value: 1e12, symbol: "T" },
                // { value: 1e15, symbol: "P" },
                // { value: 1e18, symbol: "E" }
            ];
            const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
            var item = lookup.slice().reverse().find(function (item) {
                return num >= item.value;
            });
            return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
        }
    },

}

export default mixins